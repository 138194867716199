ion-select {
    margin-top: 2%;
}

ion-textarea {
    margin-top: 2%;
}

ion-item {
    /* --inner-padding-start: 2%; */
}